const API_URL = "https://api.uat.speakup.dev.solidstudio.io";

export default {
    DEFAULT_REGION: "eu-west-2",
    cognito: {
        USER_POOL_ID: "eu-west-2_xdtXOtgQ8",
        APP_CLIENT_ID: "4gjdncc25ht3gce11nk476evlp",
        IDENTITY_POOL_ID: "eu-west-2:f15adbf9-aa71-48be-a1ba-e2af2eaef68b"
    },
    s3: {
        BUCKET: "tcup-uat-app-uploads"
    },
    apis: {
        usersApi: {
            URL: `${API_URL}/users-service`
        },
        questionsApi: {
            URL: `${API_URL}/questions-service`
        },
        commentsApi: {
            URL: `${API_URL}/comments-service`
        }
    }
};